<template>
  <div class="page-classroom-edit">
    <div class="my-page">
      <van-form @submit="save" style="background-color: transparent">
        <div class="my-card">
          <van-field
            readonly
            required
            v-model="viewData.LeaveTypeName"
            name="LeaveTypeName"
            label="请假类型"
            placeholder="请假类型"
            :rules="[{ required: true, message: '请选择请假类型' }]"
            @click="showPicker = true"
          />
          <van-popup v-model="showPicker" position="bottom">
            <van-picker
              show-toolbar
              value-key="EnumName"
              :columns="leaveTypeList"
              @confirm="onConfirm"
              @cancel="showPicker = false"
            />
          </van-popup>
          <van-field
            readonly
            required
            v-model="viewData.BeginDate"
            name="BeginDate"
            label="请假开始时间"
            placeholder="请假开始时间"
            :rules="[{ required: true, message: '请选择请假开始时间' }]"
            @click="showTime = true"
          />
          <van-popup v-model="showTime" position="bottom">
            <van-datetime-picker
              type="datetime"
              title="请选择日期"
              v-model="defaultTime"
              :min-date="minStartTime"
              @confirm="onConfirmTime"
              @cancel="showTime = false"
            />
          </van-popup>
          <van-field
            readonly
            required
            v-model="viewData.EndDate"
            name="EndDate"
            label="请假结束时间"
            placeholder="请假结束时间"
            :rules="[{ required: true, message: '请选择请假结束时间' }]"
            @click="showTime2 = true"
          />
          <van-popup v-model="showTime2" position="bottom">
            <van-datetime-picker
              type="datetime"
              title="请选择日期"
              v-model="defaultTime2"
              :min-date="minDate"
              @confirm="onConfirmTime2"
              @cancel="showTime2 = false"
            />
          </van-popup>
          <van-field
            required
            type="number"
            v-model="viewData.LeaveDays"
            name="LeaveDays"
            label="请假天数"
            placeholder="请假天数"
            @input="myChange"
            :rules="[
              { required: true, message: '请填写请假天数' },
              {
                pattern:
                  /^(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*))$/,
                message: '请填写正数',
              },
            ]"
          />
          <!-- <van-field
            required
            type="number"
            v-model="viewData.LeaveTimeCount"
            name="LeaveTimeCount"
            label="请假总时长"
            placeholder="请假总时长"
            :rules="[
              { required: true, message: '请填写请假总时长' },
              {
                pattern:
                  /^(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*))$/,
                message: '请填写正数',
              },
            ]"
          /> -->
          <van-field
            readonly
            required
            v-model="viewData.FlowIDName"
            name="FlowIDName"
            label="审核流程"
            placeholder="审核流程"
            :rules="[{ required: true, message: '请选择审核流程' }]"
            @click="showPicker2 = true"
          />
          <van-popup v-model="showPicker2" position="bottom">
            <van-picker
              show-toolbar
              value-key="Name"
              :columns="auditFlowList"
              @confirm="onConfirm2"
              @cancel="showPicker2 = false"
            />
          </van-popup>
          <van-field
            required
            v-model="viewData.LeaveDesc"
            name="LeaveDesc"
            rows="2"
            label="请假事由"
            type="textarea"
            placeholder="请输入请假事由"
            :rules="[{ required: true, message: '请输入请假事由' }]"
          />
          <van-field name="AccessoryPostList" label="附件" class="require">
            <template #input>
              <div>
                <van-uploader
                v-model="viewData.AccessoryPostList"
                @delete="deleteImg"
                :show-upload="false"
                accept="image/*"
              >
              </van-uploader>
              <span class="imgUpload" @click="chooseImage">+</span>
              </div>
            </template>
          </van-field>
        </div>
        <van-button
          :loading="loading"
          block
          color="#47AFA7"
          type="info"
          native-type="submit"
          :disabled="disabled"
          >提交</van-button
        >
      </van-form>
    </div>
  </div>
</template>

<script>
import {
  Button,
  Form,
  Field,
  Uploader,
  Popup,
  Picker,
  DatetimePicker,
  Toast,
} from "vant";
import wx from "weixin-js-sdk";
export default {
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [Uploader.name]: Uploader,
    [Button.name]: Button,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [DatetimePicker.name]: DatetimePicker,
    [Toast.name]: Toast,
  },
  computed: {
    minDate: function () {
      let date = this.defaultTime;
      return new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes() + 1
      );
    },
  },
  data() {
    return {
      id: null,
      viewData: {
        AccessoryPostList: [],
      },
      leaveTypeList: [],
      auditFlowList: [],
      showPicker: false,
      showPicker2: false,
      showTime: false,
      showTime2: false,
      loading: false,
      defaultTime: new Date(),
      defaultTime2: new Date(),
      minStartTime: new Date(),
      disabled:false
    };
  },
  mounted() {
    this.getEnum();
    this.getWechatSdk();
  },
  methods: {
    getEnum() {
      this.$axios.get("/api/Base/GetEnum?type=LeaveType").then((res) => {
        if (res.code === 200) {
          this.leaveTypeList = res.data.List;
        }
      });
    },
    onConfirm(value) {
      this.viewData.LeaveTypeName = value.EnumName;
      this.viewData.LeaveType = value.EnumValue;
      this.showPicker = false;
    },
    onConfirm2(value) {
      this.viewData.FlowIDName = value.Name;
      this.viewData.FlowID = value.ID;
      this.showPicker2 = false;
    },
    onConfirmTime(value) {
      this.viewData.BeginDate = new Date(value).format("yyyy-MM-dd hh:mm");
      this.showTime = false;
      // 计算总时长
      //this.sumTime();
    },
    onConfirmTime2(value) {
      this.viewData.EndDate = new Date(value).format("yyyy-MM-dd hh:mm");
      this.showTime2 = false;
      // 计算总时长
      //this.sumTime();
    },
    sumTime() {
      const startTime = this.viewData.BeginDate;
      const endTime = this.viewData.EndDate;
      if (startTime && endTime) {
        this.$set(
          this.viewData,
          "LeaveTimeCount",
          this.$moment(endTime)
            .diff(this.$moment(startTime), "hours", true)
            .toFixed(1)
        );
      }
    },
    beforeRead(file) {
      if (file.size / 1024 > 1025) {
        this.$compress.photoCompress(
          file,
          {
            quality: 0.2,
          },
          (base64Codes) => {
            file = this.$compress.dataURLtoFile(base64Codes, file.name);
            Toast.loading({
              message: "加载中...",
              duration: 0,
            });
            let formData = new FormData();
            formData.append("LastFile", file);
            formData.append("FileName", file.name);
            this.$axios.upload("/api/File/UploadFile", formData).then((res) => {
              Toast.clear();
              if (res.code === 200) {
                this.viewData.AccessoryPostList.push({
                  name: file.name,
                  osskey: res.data.osskey,
                  uploadUrl: res.data.uploadUrl,
                  url: res.data.url,
                });
                return true;
              } else {
                this.$toast.fail(res.msg || "操作失败");
                return false;
              }
            });
          }
        );
      } else {
        Toast.loading({
          message: "加载中...",
          duration: 0,
        });
        let formData = new FormData();
        formData.append("LastFile", file);
        formData.append("FileName", file.name);
        this.$axios.upload("/api/File/UploadFile", formData).then((res) => {
          Toast.clear();
          if (res.code === 200) {
            this.viewData.AccessoryPostList.push({
              name: file.name,
              osskey: res.data.osskey,
              uploadUrl: res.data.uploadUrl,
              url: res.data.url,
            });
            return true;
          } else {
            this.$toast.fail(res.msg || "操作失败");
            return false;
          }
        });
      }
    },
    review(file) {
      if (file.url) {
        window.open(file.url);
      }
    },
    save() {
      this.disabled = true
      let that = this;
      this.$axios.post("/api/WorkerLeave/Add", this.viewData).then((res) => {
        if (res.code === 200) {
          this.$toast.success(res.msg || "操作成功");
          // setTimeout(() => {
          //   that.$router.replace({
          //     path: 'LeaveTeacher',
          //     query: {
          //       active: 0
          //     }
          //   })
          // }, 1000)
          this.disabled = false
          this.$emit("choose");
        } else {
          this.disabled = false
          this.$toast.fail(res.msg || "操作失败");
        }
      });
    },
    myChange(e) {
      this.auditFlowList = [];
      this.viewData.FlowIDName = ''
      if (e >= 2) {
        this.$axios
          .get("/api/AuditFlow/GroupFlowList?limit=99&day=" + e)
          .then((res) => {
            if (res.code === 200) {
              this.auditFlowList = res.data;
            }
          });
      } else {
        this.$axios
          .get("/api/AuditFlow/List?limit=99&auditType=1")
          .then((res) => {
            if (res.code === 200) {
              this.auditFlowList = res.data;
            }
          });
      }
    },
    // 微信SDK 上传多图
    uploadImg(file) {
      //alert("进来了");
      let that = this;
      Toast.loading({
        message: "上传中...",
        duration: 0
      });
      let formData = new FormData();
      formData.append("LastFile", file);
      formData.append("FileName", file.name);

      that.$axios.upload("/api/File/UploadFile", formData).then(res => {
        Toast.clear();
        if (res.code === 200) {
          //console.log(res)
          that.$toast.success(res.msg);
           that.viewData.AccessoryPostList.push({
              name: file.name,
              osskey: res.data.osskey,
              uploadUrl: res.data.uploadUrl,
              url: res.data.url
            });
        } else {
          that.$toast.fail(res.msg || "操作失败");
          return false;
        }
      });
    },
    deleteImg() {},
    getWechatSdk() {
      this.$axios
        .get("/api/WeChat/WeChatAuthorization", {
          url: window.location.href.split("#")[0]
        })
        .then(res => {
          if (res.code === 200) {
            this.wxConfig(res.data[0]);
          } else {
          }
        });
    },
    wxConfig(result) {
      let _this = this;
      let timestamp = result.timeStamp;
      let noncestr = result.nonceStr;
      let signature = result.signature;
      let appId = result.appId;
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        //                                debug : true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: appId, // 必填，公众号的唯一标识
        timestamp: timestamp, // 必填，生成签名的时间戳
        nonceStr: noncestr, // 必填，生成签名的随机串
        signature: signature, // 必填，签名，见附录1
        jsApiList: [
          "onMenuShareTimeline",
          "onMenuShareAppMessage",
          "onMenuShareQQ",
          "previewImage",
          "openLocation",
          "getLocation",
          "chooseImage",
          "uploadImage",
          "downloadImage"
        ] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
      });
      wx.ready(function() {
        //setSessionStorage("wxready", true);
        console.log("微信已加载完毕！");
      });
      wx.error(function(res) {
        // alert(res)
        console.log(res);
      });
    },
    chooseImage(index, type) {
      let _this = this;
      wx.chooseImage({
        count: 9, // 默认9
        sizeType: ["compressed"], // 可以指定是原图还是压缩图，默认二者都有
        sourceType: ["album", "camera"], // 可以指定来源是相册还是相机，默认二者都有
        success: function(res) {
          let localIds = res.localIds; // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
          _this.wxImgData(localIds, type);
        }
      });
    },
    wxImgData(localIds, type) {
      let _this = this;
      _this.localIdImgs = [];
      var i = 0;
      var length = localIds.length;
      var upload = function() {
        wx.getLocalImgData({
          localId: localIds[i], // 图片的localID
          success: function(res) {
            Toast.loading({
              message: "上传中...",
              duration: 0
            });
            let localData = res.localData; // localData是图片的base64数据，可以用img标签显示
            if (localData.indexOf("data:image") != 0) {
              //判断是否有这样的头部
              localData = "data:image/jpeg;base64," + localData;
            }
            localData = localData
              .replace(/\r|\n/g, "")
              .replace("data:image/jgp", "data:image/jpeg"); // 此处的localData 就是你所需要的base64位
            _this.localIdImgs.push(localData);
            if (_this.localIdImgs.length >= length) {
              //alert("_this.localIdImgs.length+" + _this.localIdImgs.length);
              _this.getBase(_this.localIdImgs); //这里就可以拿到所有的base64传给逻辑函数了
            }
            i++; //因为多图片上传采用了这个方法
            i < length && upload();
            // let file = _this.dataURLtoFile(localData);
            // // params.append("request", file);
            // //接口上传
            // alert(file);
            // _this.uploadImg(file);
          }
        });
      };
      upload();
    },
    getBase(base) {
      let _this = this;
      const photoName = `${new Date().getTime()}`;
      base.forEach(element => {
        Toast.loading({
          message: "上传中...",
          duration: 0
        });
        let initData = element.replace(/%0A|\s/g, "");
        //这里的base64ToFile就是base64转文件流的方法
        let p = _this.dataURLtoFile(initData, `${photoName}.jpg`);
        _this.uploadImg(p);
      });
    },
    dataURLtoFile(dataurl, filename = "weixin.jpg") {
      var arr = dataurl.split(","),
        //mime = arr[0].match(/:(.*?);/)[1],
        mime = "image/jpeg",
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    }
  },
};
</script>
<style lang="less" scoped>
@import "./index.less";
</style>
