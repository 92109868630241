<template>
	<div class="page-leaveteacher">
		<!-- <div class="tabbar-top">
			<van-tabbar active-color="#ffe500" :route="true">
				<van-tabbar-item replace to="/ChildrenLeave?active=0">
					<template>
						<div class="img">
							<img src="../../assets/img/1.png" style="width: 20px;height: 20px;" />
						</div>
					</template>
					<div class="text">全部</div>
				</van-tabbar-item>
				<van-tabbar-item :badge="count" v-if="count" replace to="/ChildrenLeave?active=1">
					<template>
						<div class="img">
							<img src="../../assets/img/2.png" style="width: 20px;height: 20px;" />
						</div>
					</template>
					<div class="text">待处理</div>
				</van-tabbar-item>
				<van-tabbar-item v-else replace to="/ChildrenLeave?active=1">
					<template>
						<div class="img">
							<img src="../../assets/img/2.png" style="width: 20px;height: 20px;" />
						</div>
					</template>
					<div class="text">待处理</div>
				</van-tabbar-item>
				<van-tabbar-item replace to="/ChildrenLeave?active=2">
					<template>
						<div class="img">
							<img src="../../assets/img/3.png" style="width: 20px;height: 20px;" />
						</div>
					</template>
					<div class="text">已处理</div>
				</van-tabbar-item>
				<van-tabbar-item replace to="/ChildrenLeave?active=3">
					<template>
						<div class="img">
							<img src="../../assets/img/4.png" style="width: 20px;height: 20px;" />
						</div>
					</template>
					<div class="text">我收到的</div>
				</van-tabbar-item>
			</van-tabbar>
		</div> -->
		<div class="tab-box" style="background-color: #EDEDED;justify-content: center">
			<div class="tab-item" v-for="(num, index) in tabs" :key="index" :class="{ active: index === tabNum }"
				@click="tabClick(index)" style="margin: 0 50px;">
				{{ num }}
			</div>
		</div>
		<add v-if="tabNum === 0" @choose="chooseItem" />
		<my v-if="tabNum === 1" />
		<!-- <audit v-if="tabNum === 1" /> -->
	</div>
</template>

<script>
	import {
		Icon,
		Image,
		ImagePreview,
		Tabbar,
		TabbarItem
	} from "vant";
	import Add from "./My/Add";
	import My from "./My/List";
	// import Audit from './Audit/List'

	export default {
		components: {
			My,
			Add,
			[Tabbar.name]: Tabbar,
			[TabbarItem.name]: TabbarItem,
			// Audit,
		},
		data() {
			return {
				tabs: ["我要请假", "请假记录"],
				tabNum: 0,
				count: 0,
			};
		},
		mounted() {
			// this.getUser()
			if (this.$route.query.active) {
				this.tabNum = Number(this.$route.query.active);
			}
			this._getPending();
		},
		methods: {
			tabClick(index) {
				this.tabNum = index;
			},
			_getPending() {
				this.$axios.get("/api/LeaveForChildren/GetPendingCount").then((res) => {
					if (res.code === 200) {
						this.count = res.data;
					}
				});
			},
			chooseItem() {
				this.tabNum = 1;
			},
		},
	};
</script>
<style lang="less" scoped>
	.tabbar-top {
		.img {
			display: flex;
			align-items: center;
			justify-content: center
		}

		.text {
			margin-top: 10px
		}

		/deep/.van-tabbar-item__icon {
			left: 20px
		}
	}

	@import "./index.less";
</style>
